@font-face {
	font-family: "b3-icons";
	src: url('./src/common/assets/font/b3-icons.woff');
	src: url('./src/common/assets/font/b3-icons.woff') format('woff'); 
}

@mixin b3__ico--styles {
	font-family: "b3-icons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
	text-decoration: none;
	text-transform: none;
	display: inline-block;
	vertical-align: middle;
}

%b3__ico- {
	@include b3__ico--styles;
}

@function b3__ico--char($filename) {
	$char: "";

	@if $filename == Facebook {
		$char: "\E001";
	}
	@if $filename == Glassdoor {
		$char: "\E002";
	}
	@if $filename == Instagram {
		$char: "\E003";
	}
	@if $filename == Linkedin {
		$char: "\E004";
	}
	@if $filename == Twitter {
		$char: "\E005";
	}
	@if $filename == YouTube {
		$char: "\E006";
	}
	@if $filename == account_circle {
		$char: "\E007";
	}
	@if $filename == add {
		$char: "\E008";
	}
	@if $filename == add_circle_outline {
		$char: "\E009";
	}
	@if $filename == add_comment {
		$char: "\E00A";
	}
	@if $filename == add_shopping_cart {
		$char: "\E00B";
	}
	@if $filename == alarm_off {
		$char: "\E00C";
	}
	@if $filename == alarm_on {
		$char: "\E00D";
	}
	@if $filename == apps {
		$char: "\E00E";
	}
	@if $filename == arrow_back {
		$char: "\E00F";
	}
	@if $filename == arrow_downward {
		$char: "\E010";
	}
	@if $filename == arrow_drop_down {
		$char: "\E011";
	}
	@if $filename == arrow_drop_up {
		$char: "\E012";
	}
	@if $filename == arrow_forward {
		$char: "\E013";
	}
	@if $filename == arrow_left {
		$char: "\E014";
	}
	@if $filename == arrow_right {
		$char: "\E015";
	}
	@if $filename == arrow_upward {
		$char: "\E016";
	}
	@if $filename == attach_file {
		$char: "\E017";
	}
	@if $filename == attach_money {
		$char: "\E018";
	}
	@if $filename == attachment {
		$char: "\E019";
	}
	@if $filename == autorenew {
		$char: "\E01A";
	}
	@if $filename == bar_chart {
		$char: "\E01B";
	}
	@if $filename == block {
		$char: "\E01C";
	}
	@if $filename == bookmark {
		$char: "\E01D";
	}
	@if $filename == bookmark_border {
		$char: "\E01E";
	}
	@if $filename == bookmarks {
		$char: "\E01F";
	}
	@if $filename == chat {
		$char: "\E020";
	}
	@if $filename == chat_bubble {
		$char: "\E021";
	}
	@if $filename == chat_bubble_outline {
		$char: "\E022";
	}
	@if $filename == check {
		$char: "\E023";
	}
	@if $filename == check_after {
		$char: "\E024";
	}
	@if $filename == check_circle {
		$char: "\E025";
	}
	@if $filename == clear {
		$char: "\E026";
	}
	@if $filename == clear_all {
		$char: "\E027";
	}
	@if $filename == cloud {
		$char: "\E028";
	}
	@if $filename == cloud_done {
		$char: "\E029";
	}
	@if $filename == cloud_download {
		$char: "\E02A";
	}
	@if $filename == cloud_off {
		$char: "\E02B";
	}
	@if $filename == cloud_upload {
		$char: "\E02C";
	}
	@if $filename == compare_arrows {
		$char: "\E02D";
	}
	@if $filename == computer {
		$char: "\E02E";
	}
	@if $filename == create_new_folder {
		$char: "\E02F";
	}
	@if $filename == credit_card {
		$char: "\E030";
	}
	@if $filename == dashboard {
		$char: "\E031";
	}
	@if $filename == date_range {
		$char: "\E032";
	}
	@if $filename == delete {
		$char: "\E033";
	}
	@if $filename == description {
		$char: "\E034";
	}
	@if $filename == desktop_windows {
		$char: "\E035";
	}
	@if $filename == devices {
		$char: "\E036";
	}
	@if $filename == done_all {
		$char: "\E037";
	}
	@if $filename == double_arrow_left {
		$char: "\E038";
	}
	@if $filename == double_arrow_right {
		$char: "\E039";
	}
	@if $filename == download {
		$char: "\E03A";
	}
	@if $filename == drafts {
		$char: "\E03B";
	}
	@if $filename == edit {
		$char: "\E03C";
	}
	@if $filename == error_outline {
		$char: "\E03D";
	}
	@if $filename == event_available {
		$char: "\E03E";
	}
	@if $filename == exit_to_app {
		$char: "\E03F";
	}
	@if $filename == file_copy {
		$char: "\E040";
	}
	@if $filename == filter_list {
		$char: "\E041";
	}
	@if $filename == fingerprint {
		$char: "\E042";
	}
	@if $filename == first_page {
		$char: "\E043";
	}
	@if $filename == folder {
		$char: "\E044";
	}
	@if $filename == folder_open {
		$char: "\E045";
	}
	@if $filename == group_add {
		$char: "\E046";
	}
	@if $filename == help_outline {
		$char: "\E047";
	}
	@if $filename == home {
		$char: "\E048";
	}
	@if $filename == home_work {
		$char: "\E049";
	}
	@if $filename == info {
		$char: "\E04A";
	}
	@if $filename == insert_chart {
		$char: "\E04B";
	}
	@if $filename == insert_drive_file {
		$char: "\E04C";
	}
	@if $filename == insert_invitation {
		$char: "\E04D";
	}
	@if $filename == keyboard {
		$char: "\E04E";
	}
	@if $filename == keyboard_arrow_down {
		$char: "\E04F";
	}
	@if $filename == keyboard_arrow_left {
		$char: "\E050";
	}
	@if $filename == keyboard_arrow_right {
		$char: "\E051";
	}
	@if $filename == keyboard_arrow_up {
		$char: "\E052";
	}
	@if $filename == label {
		$char: "\E053";
	}
	@if $filename == label_off {
		$char: "\E054";
	}
	@if $filename == last_page {
		$char: "\E055";
	}
	@if $filename == list {
		$char: "\E056";
	}
	@if $filename == local_phone {
		$char: "\E057";
	}
	@if $filename == lock {
		$char: "\E058";
	}
	@if $filename == lock_open {
		$char: "\E059";
	}
	@if $filename == logo {
		$char: "\E05A";
	}
	@if $filename == logout {
		$char: "\E05B";
	}
	@if $filename == mail {
		$char: "\E05C";
	}
	@if $filename == menu {
		$char: "\E05D";
	}
	@if $filename == money_off {
		$char: "\E05E";
	}
	@if $filename == mood {
		$char: "\E05F";
	}
	@if $filename == mood_bad {
		$char: "\E060";
	}
	@if $filename == more_horiz {
		$char: "\E061";
	}
	@if $filename == more_vert {
		$char: "\E062";
	}
	@if $filename == note_add {
		$char: "\E063";
	}
	@if $filename == notifications {
		$char: "\E064";
	}
	@if $filename == notifications_off {
		$char: "\E065";
	}
	@if $filename == open_in_new {
		$char: "\E066";
	}
	@if $filename == people {
		$char: "\E067";
	}
	@if $filename == person {
		$char: "\E068";
	}
	@if $filename == person_add {
		$char: "\E069";
	}
	@if $filename == pie_chart {
		$char: "\E06A";
	}
	@if $filename == print {
		$char: "\E06B";
	}
	@if $filename == radio_after {
		$char: "\E06C";
	}
	@if $filename == remove {
		$char: "\E06D";
	}
	@if $filename == remove_circle_outline {
		$char: "\E06E";
	}
	@if $filename == reply {
		$char: "\E06F";
	}
	@if $filename == reply_all {
		$char: "\E070";
	}
	@if $filename == restore {
		$char: "\E071";
	}
	@if $filename == room {
		$char: "\E072";
	}
	@if $filename == save {
		$char: "\E073";
	}
	@if $filename == schedule {
		$char: "\E074";
	}
	@if $filename == school {
		$char: "\E075";
	}
	@if $filename == search {
		$char: "\E076";
	}
	@if $filename == send {
		$char: "\E077";
	}
	@if $filename == sentiment_dissatisfied {
		$char: "\E078";
	}
	@if $filename == sentiment_satisfied {
		$char: "\E079";
	}
	@if $filename == settings {
		$char: "\E07A";
	}
	@if $filename == share {
		$char: "\E07B";
	}
	@if $filename == shopping_cart {
		$char: "\E07C";
	}
	@if $filename == smartphone {
		$char: "\E07D";
	}
	@if $filename == sort {
		$char: "\E07E";
	}
	@if $filename == speed {
		$char: "\E07F";
	}
	@if $filename == star {
		$char: "\E080";
	}
	@if $filename == star_border {
		$char: "\E081";
	}
	@if $filename == star_half {
		$char: "\E082";
	}
	@if $filename == swap_horiz {
		$char: "\E083";
	}
	@if $filename == swap_horizontal_circle {
		$char: "\E084";
	}
	@if $filename == swap_vert {
		$char: "\E085";
	}
	@if $filename == swap_vertical_circle {
		$char: "\E086";
	}
	@if $filename == tablet_android {
		$char: "\E087";
	}
	@if $filename == textsms {
		$char: "\E088";
	}
	@if $filename == thumb_down {
		$char: "\E089";
	}
	@if $filename == thumb_up {
		$char: "\E08A";
	}
	@if $filename == timeline {
		$char: "\E08B";
	}
	@if $filename == trending_down {
		$char: "\E08C";
	}
	@if $filename == trending_flat {
		$char: "\E08D";
	}
	@if $filename == trending_up {
		$char: "\E08E";
	}
	@if $filename == upload {
		$char: "\E08F";
	}
	@if $filename == verified_user {
		$char: "\E090";
	}
	@if $filename == visibility {
		$char: "\E091";
	}
	@if $filename == visibility_off {
		$char: "\E092";
	}
	@if $filename == zoom_in {
		$char: "\E093";
	}
	@if $filename == zoom_out {
		$char: "\E094";
	}

	@return $char;
}

@mixin b3__ico-($filename, $insert: before, $extend: true) {
	&:#{$insert} {
		@if $extend {
			@extend %b3__ico-;
		} @else {
			@include b3__ico--styles;
		}
		content: b3__ico--char($filename);
	}
}

.b3__ico--Facebook {
	@include b3__ico-(Facebook);
}
.b3__ico--Glassdoor {
	@include b3__ico-(Glassdoor);
}
.b3__ico--Instagram {
	@include b3__ico-(Instagram);
}
.b3__ico--Linkedin {
	@include b3__ico-(Linkedin);
}
.b3__ico--Twitter {
	@include b3__ico-(Twitter);
}
.b3__ico--YouTube {
	@include b3__ico-(YouTube);
}
.b3__ico--account_circle {
	@include b3__ico-(account_circle);
}
.b3__ico--add {
	@include b3__ico-(add);
}
.b3__ico--add_circle_outline {
	@include b3__ico-(add_circle_outline);
}
.b3__ico--add_comment {
	@include b3__ico-(add_comment);
}
.b3__ico--add_shopping_cart {
	@include b3__ico-(add_shopping_cart);
}
.b3__ico--alarm_off {
	@include b3__ico-(alarm_off);
}
.b3__ico--alarm_on {
	@include b3__ico-(alarm_on);
}
.b3__ico--apps {
	@include b3__ico-(apps);
}
.b3__ico--arrow_back {
	@include b3__ico-(arrow_back);
}
.b3__ico--arrow_downward {
	@include b3__ico-(arrow_downward);
}
.b3__ico--arrow_drop_down {
	@include b3__ico-(arrow_drop_down);
}
.b3__ico--arrow_drop_up {
	@include b3__ico-(arrow_drop_up);
}
.b3__ico--arrow_forward {
	@include b3__ico-(arrow_forward);
}
.b3__ico--arrow_left {
	@include b3__ico-(arrow_left);
}
.b3__ico--arrow_right {
	@include b3__ico-(arrow_right);
}
.b3__ico--arrow_upward {
	@include b3__ico-(arrow_upward);
}
.b3__ico--attach_file {
	@include b3__ico-(attach_file);
}
.b3__ico--attach_money {
	@include b3__ico-(attach_money);
}
.b3__ico--attachment {
	@include b3__ico-(attachment);
}
.b3__ico--autorenew {
	@include b3__ico-(autorenew);
}
.b3__ico--bar_chart {
	@include b3__ico-(bar_chart);
}
.b3__ico--block {
	@include b3__ico-(block);
}
.b3__ico--bookmark {
	@include b3__ico-(bookmark);
}
.b3__ico--bookmark_border {
	@include b3__ico-(bookmark_border);
}
.b3__ico--bookmarks {
	@include b3__ico-(bookmarks);
}
.b3__ico--chat {
	@include b3__ico-(chat);
}
.b3__ico--chat_bubble {
	@include b3__ico-(chat_bubble);
}
.b3__ico--chat_bubble_outline {
	@include b3__ico-(chat_bubble_outline);
}
.b3__ico--check {
	@include b3__ico-(check);
}
.b3__ico--check_after {
	@include b3__ico-(check_after);
}
.b3__ico--check_circle {
	@include b3__ico-(check_circle);
}
.b3__ico--clear {
	@include b3__ico-(clear);
}
.b3__ico--clear_all {
	@include b3__ico-(clear_all);
}
.b3__ico--cloud {
	@include b3__ico-(cloud);
}
.b3__ico--cloud_done {
	@include b3__ico-(cloud_done);
}
.b3__ico--cloud_download {
	@include b3__ico-(cloud_download);
}
.b3__ico--cloud_off {
	@include b3__ico-(cloud_off);
}
.b3__ico--cloud_upload {
	@include b3__ico-(cloud_upload);
}
.b3__ico--compare_arrows {
	@include b3__ico-(compare_arrows);
}
.b3__ico--computer {
	@include b3__ico-(computer);
}
.b3__ico--create_new_folder {
	@include b3__ico-(create_new_folder);
}
.b3__ico--credit_card {
	@include b3__ico-(credit_card);
}
.b3__ico--dashboard {
	@include b3__ico-(dashboard);
}
.b3__ico--date_range {
	@include b3__ico-(date_range);
}
.b3__ico--delete {
	@include b3__ico-(delete);
}
.b3__ico--description {
	@include b3__ico-(description);
}
.b3__ico--desktop_windows {
	@include b3__ico-(desktop_windows);
}
.b3__ico--devices {
	@include b3__ico-(devices);
}
.b3__ico--done_all {
	@include b3__ico-(done_all);
}
.b3__ico--double_arrow_left {
	@include b3__ico-(double_arrow_left);
}
.b3__ico--double_arrow_right {
	@include b3__ico-(double_arrow_right);
}
.b3__ico--download {
	@include b3__ico-(download);
}
.b3__ico--drafts {
	@include b3__ico-(drafts);
}
.b3__ico--edit {
	@include b3__ico-(edit);
}
.b3__ico--error_outline {
	@include b3__ico-(error_outline);
}
.b3__ico--event_available {
	@include b3__ico-(event_available);
}
.b3__ico--exit_to_app {
	@include b3__ico-(exit_to_app);
}
.b3__ico--file_copy {
	@include b3__ico-(file_copy);
}
.b3__ico--filter_list {
	@include b3__ico-(filter_list);
}
.b3__ico--fingerprint {
	@include b3__ico-(fingerprint);
}
.b3__ico--first_page {
	@include b3__ico-(first_page);
}
.b3__ico--folder {
	@include b3__ico-(folder);
}
.b3__ico--folder_open {
	@include b3__ico-(folder_open);
}
.b3__ico--group_add {
	@include b3__ico-(group_add);
}
.b3__ico--help_outline {
	@include b3__ico-(help_outline);
}
.b3__ico--home {
	@include b3__ico-(home);
}
.b3__ico--home_work {
	@include b3__ico-(home_work);
}
.b3__ico--info {
	@include b3__ico-(info);
}
.b3__ico--insert_chart {
	@include b3__ico-(insert_chart);
}
.b3__ico--insert_drive_file {
	@include b3__ico-(insert_drive_file);
}
.b3__ico--insert_invitation {
	@include b3__ico-(insert_invitation);
}
.b3__ico--keyboard {
	@include b3__ico-(keyboard);
}
.b3__ico--keyboard_arrow_down {
	@include b3__ico-(keyboard_arrow_down);
}
.b3__ico--keyboard_arrow_left {
	@include b3__ico-(keyboard_arrow_left);
}
.b3__ico--keyboard_arrow_right {
	@include b3__ico-(keyboard_arrow_right);
}
.b3__ico--keyboard_arrow_up {
	@include b3__ico-(keyboard_arrow_up);
}
.b3__ico--label {
	@include b3__ico-(label);
}
.b3__ico--label_off {
	@include b3__ico-(label_off);
}
.b3__ico--last_page {
	@include b3__ico-(last_page);
}
.b3__ico--list {
	@include b3__ico-(list);
}
.b3__ico--local_phone {
	@include b3__ico-(local_phone);
}
.b3__ico--lock {
	@include b3__ico-(lock);
}
.b3__ico--lock_open {
	@include b3__ico-(lock_open);
}
.b3__ico--logo {
	@include b3__ico-(logo);
}
.b3__ico--logout {
	@include b3__ico-(logout);
}
.b3__ico--mail {
	@include b3__ico-(mail);
}
.b3__ico--menu {
	@include b3__ico-(menu);
}
.b3__ico--money_off {
	@include b3__ico-(money_off);
}
.b3__ico--mood {
	@include b3__ico-(mood);
}
.b3__ico--mood_bad {
	@include b3__ico-(mood_bad);
}
.b3__ico--more_horiz {
	@include b3__ico-(more_horiz);
}
.b3__ico--more_vert {
	@include b3__ico-(more_vert);
}
.b3__ico--note_add {
	@include b3__ico-(note_add);
}
.b3__ico--notifications {
	@include b3__ico-(notifications);
}
.b3__ico--notifications_off {
	@include b3__ico-(notifications_off);
}
.b3__ico--open_in_new {
	@include b3__ico-(open_in_new);
}
.b3__ico--people {
	@include b3__ico-(people);
}
.b3__ico--person {
	@include b3__ico-(person);
}
.b3__ico--person_add {
	@include b3__ico-(person_add);
}
.b3__ico--pie_chart {
	@include b3__ico-(pie_chart);
}
.b3__ico--print {
	@include b3__ico-(print);
}
.b3__ico--radio_after {
	@include b3__ico-(radio_after);
}
.b3__ico--remove {
	@include b3__ico-(remove);
}
.b3__ico--remove_circle_outline {
	@include b3__ico-(remove_circle_outline);
}
.b3__ico--reply {
	@include b3__ico-(reply);
}
.b3__ico--reply_all {
	@include b3__ico-(reply_all);
}
.b3__ico--restore {
	@include b3__ico-(restore);
}
.b3__ico--room {
	@include b3__ico-(room);
}
.b3__ico--save {
	@include b3__ico-(save);
}
.b3__ico--schedule {
	@include b3__ico-(schedule);
}
.b3__ico--school {
	@include b3__ico-(school);
}
.b3__ico--search {
	@include b3__ico-(search);
}
.b3__ico--send {
	@include b3__ico-(send);
}
.b3__ico--sentiment_dissatisfied {
	@include b3__ico-(sentiment_dissatisfied);
}
.b3__ico--sentiment_satisfied {
	@include b3__ico-(sentiment_satisfied);
}
.b3__ico--settings {
	@include b3__ico-(settings);
}
.b3__ico--share {
	@include b3__ico-(share);
}
.b3__ico--shopping_cart {
	@include b3__ico-(shopping_cart);
}
.b3__ico--smartphone {
	@include b3__ico-(smartphone);
}
.b3__ico--sort {
	@include b3__ico-(sort);
}
.b3__ico--speed {
	@include b3__ico-(speed);
}
.b3__ico--star {
	@include b3__ico-(star);
}
.b3__ico--star_border {
	@include b3__ico-(star_border);
}
.b3__ico--star_half {
	@include b3__ico-(star_half);
}
.b3__ico--swap_horiz {
	@include b3__ico-(swap_horiz);
}
.b3__ico--swap_horizontal_circle {
	@include b3__ico-(swap_horizontal_circle);
}
.b3__ico--swap_vert {
	@include b3__ico-(swap_vert);
}
.b3__ico--swap_vertical_circle {
	@include b3__ico-(swap_vertical_circle);
}
.b3__ico--tablet_android {
	@include b3__ico-(tablet_android);
}
.b3__ico--textsms {
	@include b3__ico-(textsms);
}
.b3__ico--thumb_down {
	@include b3__ico-(thumb_down);
}
.b3__ico--thumb_up {
	@include b3__ico-(thumb_up);
}
.b3__ico--timeline {
	@include b3__ico-(timeline);
}
.b3__ico--trending_down {
	@include b3__ico-(trending_down);
}
.b3__ico--trending_flat {
	@include b3__ico-(trending_flat);
}
.b3__ico--trending_up {
	@include b3__ico-(trending_up);
}
.b3__ico--upload {
	@include b3__ico-(upload);
}
.b3__ico--verified_user {
	@include b3__ico-(verified_user);
}
.b3__ico--visibility {
	@include b3__ico-(visibility);
}
.b3__ico--visibility_off {
	@include b3__ico-(visibility_off);
}
.b3__ico--zoom_in {
	@include b3__ico-(zoom_in);
}
.b3__ico--zoom_out {
	@include b3__ico-(zoom_out);
}