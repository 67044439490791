/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

 @import "scss/functions";
 
 @import "../../common/scss/itcssImports/vars";
 @import "../../common/scss/itcssImports/mixins";
 @import "./settings/variables";

 @import "scss/mixins";
 @import "scss/root";
 @import "scss/reboot";
 @import "scss/type";
 @import "scss/images";
 @import "scss/code";
 @import "scss/grid";
 @import "scss/tables";
 @import "scss/forms";
 @import "scss/buttons";
 @import "scss/transitions";
 @import "scss/dropdown";
 @import "scss/button-group";
 @import "scss/input-group";
 @import "scss/custom-forms";
 @import "scss/nav";
 @import "scss/navbar";
 @import "scss/card";
 @import "scss/breadcrumb";
 @import "scss/pagination";
 @import "scss/badge";
 @import "scss/jumbotron";
 @import "scss/alert";
 @import "scss/progress";
 @import "scss/media";
 @import "scss/list-group";
 @import "scss/close";
 @import "scss/toasts";
 @import "scss/modal";
 @import "scss/tooltip";
 @import "scss/popover";
 @import "scss/carousel";
 @import "scss/spinners";
 @import "scss/utilities";
 @import "scss/print";
 

$bootstrap: true;
$foundation: false;
@import "../../common/scss/itcssImports/generic";
@import "../../common/scss/itcssImports/base";
@import "../../common/scss/itcssImports/objects";
@import "../../common/scss/itcssImports/components";
@import "../../common/scss/itcssImports/utils";
