@mixin __button-group__base {
    @include __text__button;
    
    &:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: 0;
    }

    &:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-right: 0;
    }
    
}

@mixin __button-group__padding($size: 24) {
    &:not(:first-child):not(.b3__btn-with-icon--left):not(.b3__btn-with-icon--only):not(.arrow-only) {
        padding-left: __remcalc($size);
    }

    &:not(:last-child):not(.b3__btn-with-icon--right):not(.b3__btn-with-icon--only) {
        padding-right: __remcalc($size);
    }
}

@mixin __button-group__outline-fix {
    &:not(:first-child) {
        border-left-width: 0;
    }

    &:not(:last-child) {
        border-right-width: 0;
    }
}

@mixin __button-group__bootstrap-fix {
    & > .btn {
        border-radius: 0;

        &.dropdown-toggle {
            padding-right: __remcalc(48) !important;
        }
    }

    &:first-child {
        & > .btn {
            &.dropdown-toggle {
                border-top-left-radius: __remcalc(48);
                border-bottom-left-radius: __remcalc(48);
            }
        }
    }

    &:last-child {
        & > .btn {
            &.dropdown-toggle {
                border-top-right-radius: __remcalc(48);
                border-bottom-right-radius: __remcalc(48);
            }
        }
    }
    
    &:not(:first-child) {
        & > .btn {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            margin-left: 0;
            padding-left: __remcalc(24);
        }
    }

    &:not(:last-child) {
        & > .btn {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            margin-right: 0;
            padding-right: __remcalc(24);
        }
    }

    
}

@if $bootstrap {
    .btn-group {
        & > .btn {
            @include __button-group__base;
            @include __button-group__padding;

            &-outline-primary, &-outline-light,
            &-outline-secondary, &-outline-dark, &-outline-info,
            &-outline-success,
            &-outline-danger, &-outline-warning {
                @include __button-group__outline-fix;
            }
        }

        & > .btn-group {
            @include __button-group__bootstrap-fix;
        }

        &.btn-group-sm {
            & > .btn {
                @extend .btn-sm;
                @include __button-group__padding(18);
            }
        }

        &.btn-group-lg {
            & > .btn {
                @extend .btn-lg;
                @include __button-group__padding(30);
            }
        }

        &-vertical {
            & > .btn {
                margin-bottom: 0;

                &:first-child {
                    border-top-left-radius: __remcalc(3);
                    border-top-right-radius: __remcalc(3);
                }

                &:last-child {
                    border-bottom-left-radius: __remcalc(3);
                    border-bottom-right-radius: __remcalc(3);
                }
            }

            & > .btn-group {
                & > .btn {
                    padding-left: __remcalc(48) !important;
                    padding-right: __remcalc(48) !important;
                }

                &:first-child {
                    & > .btn {
                        border-top-left-radius: __remcalc(3);
                        border-top-right-radius: __remcalc(3);
                    }
                }

                &:last-child {
                    & > .btn {
                        border-bottom-left-radius: __remcalc(3);
                        border-bottom-right-radius: __remcalc(3);
                    }
                }
            }
        }
    }
}

@if $foundation {
    .button-group {
        .button {
            @include __button-group__base;
            @include __button-group__padding;

            &.hollow {
                @include __button-group__outline-fix;
            }
        }

        &.primary {
            .button {
                @include __button__theme--solid(primary);
            }
        }

        &.success {
            .button {
                @include __button__theme--solid(success);
            }
        }

        &.alert, &.warning {
            .button {
                @include __button__theme--solid(alert);
            }
        }

        &.hollow {
            .button {
                @include __button__theme--outline(primary);
                @include __button-group__outline-fix; 
            }

            &.secondary {
                .button {
                    @include __button__theme--outline(secondary);
                    @include __button-group__outline-fix; 
                }
            }
    
            &.success {
                .button {
                    @include __button__theme--outline(success);
                    @include __button-group__outline-fix; 
                }
            }
    
            &.alert, &.warning {
                .button {
                    @include __button__theme--outline(alert);
                    @include __button-group__outline-fix; 
                }
            }
        }

        &.clear {
            .button {
                @include __button__theme--clear(primary);
            }

            &.secondary {
                .button {
                    @include __button__theme--clear(secondary);
                }
            }
    
            &.success {
                .button {
                    @include __button__theme--clear(success);
                }
            }
    
            &.alert, &.warning {
                .button {
                    @include __button__theme--clear(alert);
                }
            }
        }

        &.tiny {
            .button {
                @extend .button.tiny;
                @include __button-group__padding(6);
            }
        }

        &.small {
            .button {
                @extend .button.small;
                @include __button-group__padding(18);
            }
        }

        &.large {
            .button {
                @extend .button.large;
                @include __button-group__padding(30);
            }
        }
    }
}
