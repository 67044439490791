.b3__form {
    &-file {
        position: relative;
        height: __remcalc(48);
        overflow: hidden;
        @extend .b3__form-control;

        input {
            -webkit-appearance: none;
            appearance: none;
            position: absolute;
            transform: translateY(-500%);
            top: 0;
            width: auto;
        }
        
        &:after {
            @include __form-control__icon(attach_file);
        }

        label {
            height: __remcalc(48);
            left: 0;
            width: 100%;
            top: 0;
            transform: translateY(0);
            padding: 0 __remcalc(48) 0 __remcalc(12);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
            border-bottom: 1px solid $__focus;
            // @include __text__caption;
            line-height: __remcalc(48);
            pointer-events: all;
        }

        &.is-focused:not(.small) > label,
        &.has-value:not(.small) > label,
        &.has-placeholder:not(.small) > label {
            @include __text__caption;
            line-height: __remcalc(48);
            top: 0;
            color: $__text--light;

            @include __on-theme--dark {
                color: $__text--dark;
            }

            @include __on-theme--light {
                color: $__text--light;
            }
        }
    }
}