.b3__form {
    &-label {
        position: relative;
        padding-top: __remcalc(18);
        
        & > label:first-child {
            position: absolute;
            @include __form-control__label--reduced;
            top: __remcalc(1);
            margin-left: __remcalc(12);
            @include __form-control__label__color;
        }

        & .b3__form-checkbox,
        & .b3__form-radio {
            & + label {
                margin-top: __remcalc(-6);
                margin-bottom: __remcalc(-12);
            }
        }

    }

    &-checkbox,
    &-radio {
        position: absolute;
        opacity: 0;

        & ~ label {
            position: relative;
            display: inline-block;
            padding: __remcalc(12) 0 __remcalc(12) __remcalc(36);
            @include __text__caption;
            line-height: __remcalc(24);
            cursor: pointer;
            min-height: __remcalc(48);
            margin: 0 __remcalc(12);
            color: $__text__emphasis--light;

            @include __on-theme--dark {
                color: $__text__emphasis--dark;
            }

            @include __on-theme--light {
                color: $__text__emphasis--light;
            }

            &:before,
            &:after {
                content: '';
                position: absolute;
                top: __remcalc(12);
                left: __remcalc(0);
                width: __remcalc(24);
                height: __remcalc(24);
                transition: all .2s ease;
                overflow: hidden;
                text-align: center;
            }

            &:before {
                border: __remcalc(2) solid $__focus;
                background: $__bg--light;

                @include __on-theme--dark {
                    background: $__bg--dark;
                }

                @include __on-theme--trader {
                    background: $__bg--trader;
                }

                @include __on-theme--light {
                    background: $__bg--light;
                }
            }

            &:after {
                font-family: "b3-icons";
                font-size: __remcalc(1);
                color: transparent;
                box-shadow: 0 0 0 $__focus inset; 
            }

            &:hover:before {
                @include __form-switch__track--hover;
            }
        }

        &:focus + label {
            &:before {
                @include __form-switch__track--focus;
            }
        }

        &:checked + label {
            &:after {
                box-shadow: 0 0 0 __remcalc(24) $__focus inset;
                font-size: __remcalc(20);
                color: $__white;
            }
        }

        &:disabled + label {
            cursor: not-allowed;
            color: $__disabled;

            

            &:before {
                border-color: $__disabled--light;

                @include __on-theme--dark {
                    border-color: $__bg__white--2;
                }
    
                @include __on-theme--light {
                    border-color: $__disabled--light;
                }
            }

            &:hover:before {
                box-shadow: none;
            }
        }

        &:disabled:checked + label {
            &:after {
                box-shadow: none;
                color: $__disabled--light;

                @include __on-theme--dark {
                    color: $__bg__white--2;
                }
    
                @include __on-theme--light {
                    color: $__disabled--light;
                }
            }
        }

        @at-root .was-validated & {
            & ~ label {
                color: $__text__emphasis--light;

                @include __on-theme--dark {
                    color: $__text__emphasis--dark;
                }

                @include __on-theme--light {
                    color: $__text__emphasis--light;
                }
            }

            &:invalid ~ label:before {
                border-color: $__negative--dark;
            }
        }
    }

    &-checkbox {
        & ~ label {
            text-align: center;

            &:before,
            &:after {
                border-radius: __remcalc(6);
            }

            &:after {
                content: b3__ico--char(check_after);
            }
        }
    }

    &-radio {
        & ~ label {
            &:before,
            &:after {
                border-radius: 50%;
            }

            &:after {
                content: b3__ico--char(radio_after);
            }
        }

        &:checked + label {
            &:after {
                font-size: __remcalc(16);
            }
        }
        
    }
}

@if $bootstrap {
    .form-check {
        padding: 0;

        @at-root .was-validated & {
            &-input:invalid ~ .form-check-label,
            &-input:valid ~ .form-check-label, {
                color: $__text__emphasis--light;

                @include __on-theme--dark {
                    color: $__text__emphasis--dark;
                }

                @include __on-theme--light {
                    color: $__text__emphasis--light;
                }
            }
        }

        &-inline {
            margin-right: 0;

            .form-check-input {
                position: absolute;
            }
        }
    }
}