$__borderSolidColor: #E3E5E7;


@mixin __table__cell--base {
    line-height: __remcalc(20.25) !important;
    padding: __remcalc(14) __remcalc(24);
}

@mixin __table__thead {
    background: none;

    th[rowspan] {
        vertical-align: bottom;
    }

    th[colspan] {
        text-align: center;
    }

    th {
        @include __table__cell--base;
        @include __table__thead--level1;
    }

    .b3__head--level2 {
        th {
            @include __table__thead--level2;
        }
    }

    .b3__head--level3 {
        th {
            @include __table__thead--level3;
        }
    }

    .b3__head--level4 {
        th {
            @include __table__thead--level4;
        }
    }
}


@mixin __table__thead--level1 {
    @include __text__caption--large;
    border-top: 2px solid $__menu--light;
    border-bottom: none;
    color: $__text__emphasis--light;

    @include __on-theme--dark {
        border-top-color: $__menu--dark;
        color: $__text__emphasis--dark;
    }   

    @include __on-theme--light {
        border-top-color: $__menu--light;
        color: $__text__emphasis--light;
    }
}

@mixin __table__thead--level2 {
    @include __text__body--small;
    background: $__bg__gray--2;
    border-left: 1px solid $__white;
    border-bottom: 1px solid $__borderSolidColor;
    font-weight: 400;
    color: $__text__emphasis--light;

    @include __on-theme--dark {
        background: $__disabled--light;
        border-left-color: $__bg--dark;
        border-bottom-color: transparent;
        color: $__text__emphasis--dark;
    }

    @include __on-theme--trader {
        border-left-color: $__bg--trader;
    }

    @include __on-theme--light {
        background: $__bg__gray--2;
        border-left-color: $__white;
        border-bottom-color: $__borderSolidColor;
        color: $__text__emphasis--light;
    }
}

@mixin __table__thead--level3 {
    @include __text__body--small;
    background: $__bg__gray--1;
    border-left: 1px solid $__borderSolidColor;
    border-bottom: 1px solid $__borderSolidColor;
    border-top: none;
    font-weight: 400;

    @include __on-theme--dark {
        background: $__disabled--dark;
        border-left-color: $__bg--dark;
        border-bottom-color: transparent;
        color: $__text__emphasis--dark;
    }
    
    @include __on-theme--trader {
        border-left-color: $__bg--trader;
    }

    @include __on-theme--light {
        background: $__bg__gray--1;
        border-left-color: $__borderSolidColor;
        border-bottom-color: $__borderSolidColor;
        color: $__text__emphasis--light;
    }
}

@mixin __table__thead--level4 {
    @include __text__body--small;
    border-left: 1px solid $__borderSolidColor;
    border-bottom: 1px solid $__borderSolidColor;
    border-top: none;
    font-weight: 400;

    @include __on-theme--dark {
        border-left-color: $__bg__white--2;
        border-bottom-color: $__bg__white--2;
        color: $__text__emphasis--dark;
    }   

    @include __on-theme--light {
        border-left-color: $__borderSolidColor;
        border-bottom-color: $__borderSolidColor;
        color: $__text__emphasis--light;
    }
}

@mixin __table__tbody-row {
    background: none;
    transition: background .2s;

    & > td, 
    & > th {
        vertical-align: middle;
        
        &:first-child {
            border-left: 1px solid transparent;
        }
    }

    &.is-selected {
        background: $__bg__gray--2;

        @include __on-theme--dark {
            background: $__bg__white--2;
        }
        
        @include __on-theme--light {
            background: $__bg__gray--2;
        }
        
        & > td, 
        & > th {
            &:first-child {
                border-left: 3px solid $__focus;
            }
        }
    }
}

@mixin __table__tbody-row--hover($mode) {
    $bg-light: transparent;
    $bg-dark: transparent;

    @if $mode == even {
        $bg-light: $__bg__gray--1;
        $bg-dark: $__bg__white--1;
    } @else if $mode == evenhover {
        $bg-light: $__hover--light40;
        $bg-dark: $__hover--dark40;
    } @else if $mode == hover {
        $bg-light: $__hover--light40;
        $bg-dark: $__hover--dark40;
    } 

    background: $bg-light;
    
    @include __on-theme--dark {
        background: $bg-dark;
    }

    @include __on-theme--light {
        background: $bg-light;
    }
}

@mixin __table__tbody-cell {
    @include __table__cell--base;
    @include __text__body--small;
    text-align: left;
    border-top: 1px solid $__borderSolidColor;
    border-bottom: 1px solid $__borderSolidColor;
    color: $__text--light;

    @include __on-theme--dark {
        border-top-color: $__bg__white--2;
        border-bottom-color: $__bg__white--2;
        color: $__text--dark;
    }

    @include __on-theme--light {
        border-top-color: $__borderSolidColor;
        border-bottom-color: $__borderSolidColor;
        color: $__text--light;
    }
}

@mixin __table__utils {
    .b3__form-checkbox ~ label, 
    .b3__form-radio ~ label {
        margin-left: 0;
        margin-right: __remcalc(-20);
        vertical-align: middle;
    }

    .b3__cell--without-padding {
        padding: 0 0 0 __remcalc(24);
        line-height: __remcalc(48);
        height: __remcalc(48);
    }
}

@if $bootstrap {
    .table,
    .b3__datagrid {
        @include __table__utils;

        thead {
            @include __table__thead;

            &.thead-dark {
                th {
                    @include __table__thead--level2;
                    border-top: 2px solid $__menu--light;

                    @include __on-theme--dark {
                        border-top-color: $__menu--dark;
                    }

                    @include __on-theme--light {
                        border-top-color: $__menu--light;
                    }
                }
            }

            &.thead-light {
                th {
                    @include __table__thead--level3;
                    border-top: 2px solid $__menu--light;

                    @include __on-theme--dark {
                        border-top-color: $__menu--dark;
                    }

                    @include __on-theme--light {
                        border-top-color: $__menu--light;
                    }
                }
            }
        }

        tbody, tfoot {
            tr {
                @include __table__tbody-row;
            }
    
            th, td {
                @include __table__tbody-cell;
            }
        }

        &-striped tbody tr {
            &:nth-of-type(odd) {
                background: none;
            }   

            &:nth-of-type(even) {
                @include __table__tbody-row--hover(even);
            }   
        }

        &-striped.table-hover tbody tr:hover:nth-of-type(even) {
            @include __table__tbody-row--hover(evenhover);
        }

        &-bordered {
            th, td {
                border: 1px solid $__borderSolidColor;

                @include __on-theme--dark {
                    border-color: $__bg__white--2;
                }

                @include __on-theme--light {
                    border-color: $__borderSolidColor;
                }
            }

            tbody tr {
                & > th, 
                & > td {
                    &:first-child {
                        border-left-color: $__borderSolidColor;

                        @include __on-theme--dark {
                            border-left-color: $__bg__white--2;
                        }
        
                        @include __on-theme--light {
                            border-left-color: $__borderSolidColor;
                        }
                    }
                }
            }
        }

        &-hover {
            tbody tr:hover {
                @include __table__tbody-row--hover(hover);
            }
        }
    }
}

@if $foundation {
    thead, tbody, tfoot {
        border: none;
        background: none;
    }

    thead {
        @include __table__thead;
    }

    tbody, tfoot {
        tr {
            @include __table__tbody-row;
        }

        th, td {
            @include __table__tbody-cell;
        }
    }

    table {
        @include __table__utils;

        &.hover {
            thead tr:hover {
                background: none !important;
            }
            
            tbody tr {
                &:hover {
                    @include __table__tbody-row--hover(hover);
                }
            }

            &:not(.unstriped) tbody tr:nth-of-type(even):hover {
                @include __table__tbody-row--hover(evenhover);
            }
        }

        &:not(.unstriped) tbody tr:nth-child(even) {
            @include __table__tbody-row--hover(even);
        }

        &.unstriped {
            tbody {
                background: none;

                tr {
                    background: none;
                }
            }
        }   
    }
}