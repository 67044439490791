@mixin __accordion__border {
    border-bottom: 1px solid $__bg__gray--2;

    @include __on-theme--dark {
        border-top-color: $__bg__white--1;
    }

    @include __on-theme--light {
        border-top-color: $__bg__gray--2;
    }
}

@mixin __accordion__header {
    @include __button__base(48);
    text-align: left;
    margin: 0;
    border-radius: 0;
    width: 100%;
    text-align: left;
    padding-left: __remcalc(42);
    color: $__text__emphasis--light;
    border-top: 1px solid $__bg__gray--2;

    &:before,
    &:after {
        content: '' !important;
        display: block;
        position: absolute;
        top: 50%;
        right: initial;
        margin: 0;
        transform: translateY(-50%);
        background: $__interaction--light;
        transition: all .2s ease;
    }

    &:before {
        left: __remcalc(18);
        height: __remcalc(14);
        width: __remcalc(2);
    }

    &:after {
        left: __remcalc(12);
        width: __remcalc(14);
        height: __remcalc(2);
    }

    &[aria-expanded='true'] {
        &:before {
            transform: translateY(-50%) rotate(-90deg);
        }
    }

    @include __button__ripple($__hover--light, mix($__focus,$__hover--light,10%), $__interaction--light);

    &:focus {
        box-shadow: none;
        background-color: transparent;

        &:active {
            box-shadow: 0 0 0 __remcalc(4) $__hover--dark;
        }
    }

    @include __on-theme--dark {
        color: $__text__emphasis--dark;
        border-top: 1px solid $__bg__white--1;

        &:before,
        &:after {
            background: $__interaction--dark;
        }

        &:hover:not(:disabled):not(.disabled) {
            color: $__interaction--dark;
        }

        &:focus {
            background: none;
            color: $__text__emphasis--dark;
        }
    }

    @include __on-theme--light {
        color: $__text__emphasis--light;
        border-top: 1px solid $__bg__gray--2;

        &:before,
        &:after {
            background: $__interaction--light;
        }

        &:hover:not(:disabled):not(.disabled) {
            color: $__interaction--light;
        }

        &:focus {
            color: $__text__emphasis--light;
        }
    }
}

@mixin __accordion__body {
    border: none;
    background: none;
    padding: __remcalc(18) __remcalc(24);  
    color: inherit;
}

@if $bootstrap {
    .accordion {
        @include __accordion__border;

        .card {
            border: none;
            background: none;
            border-radius: 0;
            overflow: visible;
            box-shadow: none;

            &-header {
                background: none;
                padding: 0;
                border-bottom: none;

                h2 {
                    margin: 0 !important;
                }

                .btn {
                    @include __accordion__header;
                    vertical-align: top;
                }
            }

            &-body {
                @include __accordion__body;
            }
        }
    }
}

@if $foundation {
    .accordion {
        background: none;
        @include __accordion__border;

        &-item {
            border: none;

            &:last-child:not(.is-active) > .accordion-title,
            :last-child > .accordion-content:last-child {
                border-bottom: none;
            }
        }

        &-title {
            border: none;
            @include __accordion__header;
        }

        &-content {
            @include __accordion__body;
        }

        &[disabled] {
            .accordion-title  {
                color: $__disabled;

                &:before,
                &:after {
                    background: $__disabled;
                }

                &:hover,
                &:focus,
                &:focus:active {
                    background: none;
                    color: $__disabled;
                }

                
            }
        }
    }
}