@mixin __page-item__base {
    background: none;
    border: none;
    margin: 0 __remcalc(3);

    [class^='b3__ico'] {
        font-size: __remcalc(18);
    }
}

@mixin __page-item__link {
    background: none;
    border: none;
    border-radius: 50% !important;
    @include __text__caption;
    width: __remcalc(30);
    line-height: __remcalc(30);
    height: __remcalc(30);
    font-weight: bold;
    text-align: center;
    padding: 0 !important;
    transition: all .2s;
    color: $__interaction--light;

    @include __on-theme--dark {
        color: $__interaction--dark;
    }

    @include __on-theme--light {
        color: $__interaction--light;
    }
}

@mixin __page-item__link--hover {
    &:hover {
        background: $__hover--light;
        color: $__interaction--light;

        @include __on-theme--dark {
            color: $__interaction--dark;
        }

        @include __on-theme--light {
            color: $__interaction--light;
        }
    }
}

@mixin __page-item--disabled {
    color: $__disabled--light;

    @include __on-theme--dark {
        color: $__bg__white--2;
    }

    @include __on-theme--light {
        color: $__disabled--light;
    }
}

@mixin __page-item--focused {
    line-height: __remcalc(26);
    background: none;
    border: 2px solid $__focus;
    color: $__text__emphasis--light;

    @include __on-theme--dark {
        color: $__text__emphasis--dark;
    }

    @include __on-theme--light {
        color: $__text__emphasis--light;
    }
}

@if $bootstrap {
    .page-item {
        @include __page-item__base;

        &.active .page-link {
            @include __page-item--focused;
        }

        &.disabled .page-link {
            background: none; 
            @include __page-item--disabled;
        }
    }
    .page-link {
        @include __page-item__link;
        @include __page-item__link--hover;
        
        
    }
}

@if $foundation {
    .pagination {
        display: flex;

        li {
            @include __page-item__base;
            @include __page-item__link;

            a {
                @include __page-item__link;
                @include __page-item__link--hover;
            }

            &.pagination-previous:before,
            &.pagination-previous a:before,
            &.pagination-previous.disabled:before,
            &.pagination-previous.disabled a:before,
            &.pagination-next:after,
            &.pagination-next a:after { 
                display: none;
            }

            &.current {
                @include __page-item--focused;
            }

            &.disabled {
                @include __page-item--disabled;
            }

            &.ellipsis:after {
                color: $__text--light;

                @include __on-theme--dark {
                    color: $__text--dark;
                }
            
                @include __on-theme--light {
                    color: $__text--light;
                }
            }
        }
    }
}