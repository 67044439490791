a {
    color: $__interaction--light;
    @include __text__body--link;

    &:hover {
        color: darken($__interaction--light,15%);
    }

    @include __on-theme--dark {
        color: $__interaction--dark;

        &:hover {
            color: lighten($__interaction--dark,15%);
        }
    }

    @include __on-theme--light {
        color: $__interaction--light;
        @include __text__body--link;

        &:hover {
            color: darken($__interaction--light,15%);
        }
    }
}

p {
    line-height: 1.6;
    margin-bottom: __remcalc($__module__space--default);
}

h1, h2, h3, h4, h5, h6 {
    color: $__text__emphasis--light;
    margin-bottom: __remcalc($__module__space--default);

    small {
        color: $__text--light;
        font-weight: inherit;
    }

    @include __on-theme--dark {
        color: $__text__emphasis--dark;

        small {
            color: $__text--dark;
        }
    } 

    @include __on-theme--light {
        color: $__text__emphasis--light;

        small {
            color: $__text--light;
            font-weight: inherit;
        }
    } 
}

h1, .b3__text-title--1 {
    @include __text__title--1;
}

h2, .b3__text-title--2 {
    @include __text__title--2;
}

h3, .b3__text-title--3 {
    @include __text__title--3;
}

h4, .b3__text-title--4 {
    @include __text__title--4;
}

h5, .b3__text-subtitle {
    @include __text__subhead;
}

h6, .b3__text-subtitle--small {
    @include __text__subhead--small;
}

.b3__text-body {
    @include __text__body;
}

.b3__text-body--large {
    @include __text__body--large;
}

.b3__text-body--small {
    @include __text__body--small;
}

.b3__text-body--micro {
    @include __text__body--micro;
}

.b3__text-overline {
    @include __text__overline;
}

.b3__text-caption {
    @include __text__caption;
}

.b3__text-caption--large {
    @include __text__caption--large;
}

.b3__text-button {
    @include __text__button;
}

.b3__text-button--large {
    @include __text__button--large;
}

mark {
    background: $__hover--light;

    @include __on-theme--dark {
        color: $__text--dark;
    }

    @include __on-theme--light {
        color: $__text--light;
    }
}

code {
    @include __text__body--large;
    color: $__text__emphasis--light;
    background: $__hover--light;

    @include __on-theme--dark {
        color: $__text__emphasis--dark;
    }

    @include __on-theme--light {
        color: $__text__emphasis--light;
    }

}

abbr[title], abbr[data-original-title] {
    text-decoration: none;
    border-bottom: 1px dotted $__text--light;

    @include __on-theme--dark {
        border-bottom-color: $__text--dark;
    }

    @include __on-theme--light {
        border-bottom-color: $__text--light;
    }
}

@if $bootstrap {
    html {
        font-size: $__font__rem;
    }

    .h1, .display-1,
    .h2, .display-2,
    .h3, .display-3,
    .h4, .display-4,
    .h5, .lead,
    .h6 {
        color: $__text__emphasis--light;

        small {
            color: $__text--light;
            font-weight: inherit;
        }
    
        @include __on-theme--dark {
            color: $__text__emphasis--dark;
    
            small {
                color: $__text--dark;
            }
        } 

        @include __on-theme--light {
            color: $__text__emphasis--light;
    
            small {
                color: $__text--light;
            }
        } 
    }

    .h1, .display-1 {
        @include __text__title--1;
    }

    .h2, .display-2 {
        @include __text__title--2;
    }
    
    .h3, .display-3 {
        @include __text__title--3;
    }
    
    .h4, .display-4 {
        @include __text__title--4;
    }
    
    .h5, .lead {
        @include __text__subhead;
    }
    
    .h6 {
        @include __text__subhead--small;
    }

    .blockquote {
        margin: 0 0 1rem;
        padding: 0.6rem 1.33333rem 0 1.26667rem;
        border-left: 1px solid $__bg__gray--2;
        font-size: 1rem;

        @include __on-theme--dark {
            border-left-color: $__bg__white--2;
        }

        @include __on-theme--light {
            border-left-color: $__bg__gray--2;
        }
    }

    cite {
        display: block;
        font-size: 0.86667rem;

        &:before {
            content: "— ";
        }
    }
}

@if $foundation {
    blockquote, cite {
        @include __on-theme--dark {
            color: $__text--dark;
        } 

        @include __on-theme--light {
            color: $__text--light;
        } 
    }

    blockquote {
        @include __on-theme--dark {
            border-left-color: $__bg__white--2;
        } 

        @include __on-theme--light {
            border-left-color: $__bg__gray--2;
        } 
    }

    code {
        border: none;
    }
}