.b3__theme {
    &--light {
        background: $__bg__light;
        color: $__text--light;
    }
    &--dark {
        background: $__bg__dark;
        color: $__text--dark;
    }
    &--trader {
        background: $__bg__trader;
        color: $__text--dark;
    }
}