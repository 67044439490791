@mixin __modal__base {
    background: $__white;
    border-radius: __remcalc(3);
    border: none;
    @include __shadow--level4;
    max-width: 100%;
    width: 100%;

    @include __breakpoint--xsmall-only {
        margin: 0;
        min-height: 100vh;
        border-radius: 0;
    }

    @include __breakpoint--small {
        max-width: __remcalc(600);
    }
}

@mixin __modal__backdrop {
    background: $_bg__backdrop;
}

@mixin __modal__padding {
    padding: __remcalc(24);
}

@mixin __modal__title {
    @include __text__title--4;
    color: $__text--light !important;
}  

@mixin __modal__resize($size) {
    @if $size == xlarge {
        max-width: 100% !important;
    } 
    @else if $size == large {
        max-width: 90% !important;
    }
    @else if $size == small {
        max-width: 50% !important;
    }
    @else if $size == tiny {
        max-width: 30% !important;
    }
}

@if $bootstrap {
    .modal {
        &-dialog {
            @include __modal__base;
        }

        &-content {
            border: none;
        }

        &-backdrop {
            @include __modal__backdrop;

            &.show {
                opacity: 1;
            }
        }

        &-header {
            @include __modal__padding;
            border: none;
            position: relative;
        }

        &-title {
            @include __modal__title;
        }

        &-body {
            @include __modal__padding;
            padding-top: __remcalc(12);
            padding-bottom: __remcalc(12);
        }

        &-footer {
            @include __modal__padding;
            border: 0;

            .btn {
                margin-bottom: 0;
            }
        }

        .close {
            @include __alert__close-button;
            margin: 0;
            position: absolute;
        }

        &-xl {
            @include __modal__resize(xlarge);
        }

        &-lg {
            @include __modal__resize(large);
        }

        &-sm {
            @include __modal__resize(small);
        }
    }
}

@if $foundation {
    .reveal {
        @include __modal__padding;
        // fixing padding
        padding-bottom: __remcalc(9);
        @include __modal__base;
        transition: transform .2s ease-out;
        transform: scale(.8);

        &[aria-hidden='false'] {
            transform: none;
        }

        &-overlay {
            @include __modal__backdrop;
        }

        h1 {
            @include __modal__title;
            margin-bottom: __remcalc(36);
            letter-spacing: 0;
        }

        .close-button {
            @include __alert__close-button;
        }

        &.tiny {
            @include __modal__resize(tiny);
        }

        &.large {
            @include __modal__resize(large);
        }

        &.small {
            @include __modal__resize(small);
        }
    }
}