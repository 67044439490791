.b3__logo {
    @extend .b3__ico--logo;
    display: inline-block;
    font-size: __remcalc($__logo--medium);
    width: __remcalc($__logo--medium);
    height: __remcalc($__logo--medium*0.9);
    transform: translateY(__remcalc(-1.5));
    color: $__brand__main;
    position: relative;

    &:before {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
    }

    &.is-centered {
        transform: translate(__remcalc(-6),__remcalc(-1.5));
    }

    &--blue {
        color: $__brand__main;
    }

    @include __on-theme--dark {
        color: $__menu--dark;
    }

    @include __on-theme--light {
        color: $__brand__main;
    }

    &--white {
        color: $__menu--dark;
    }

    &--large {
        @extend .b3__logo;
        font-size: __remcalc($__logo--large);
        width: __remcalc($__logo--large);
        height: __remcalc($__logo--large*0.9);
        transform: translateY(__remcalc(-2));

        &.is-centered {
            transform: translate(__remcalc(-8),__remcalc(-2));
        }
    }

    &--medium {
        @extend .b3__logo;
        font-size: __remcalc($__logo--medium);
        width: __remcalc($__logo--medium);
        height: __remcalc($__logo--medium*.9);
        transform: translateY(__remcalc(-1.5));

        &.is-centered {
            transform: translate(__remcalc(-6),__remcalc(-1.5));
        }
    }

    &--small {
        @extend .b3__logo;
        font-size: __remcalc($__logo--small);
        width: __remcalc($__logo--small);
        height: __remcalc($__logo--small*.9);
        transform: translateY(__remcalc(-1));

        &.is-centered {
            transform: translate(__remcalc(-5),__remcalc(-1));
        }
    }

    
}