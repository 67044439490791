@mixin __button__base($size) {
    position: relative;
    display: inline-block;
    @include __button__resize($size);
    @include __text__button;
    overflow: hidden;
    text-overflow: ellipsis;
    background-position: center;
    transition: all 0.8s;
    margin: 0 0 1rem;

    &:focus {
        box-shadow: 0 0 0 __remcalc(2) $__hover--dark;
        outline: none;
    }

    &:focus:active {
        box-shadow: 0 0 0 __remcalc(4) $__hover--dark;
    }
}

@mixin __button__resize($size) {
    padding: __remcalc($size / 4) __remcalc($size);
    border-radius: __remcalc($size);
    line-height: __remcalc($size / 2);
    height: __remcalc($size);
}

@mixin __button__update-corner($size) {
    padding-left: __remcalc($size / 2);
    padding-right: __remcalc($size / 2);
}

@mixin __button__ripple($hoverColor,$activeColor,$textColor) {
    &:hover:not(:disabled):not(.disabled) {
        background: $hoverColor radial-gradient(circle, transparent 1%, $hoverColor 1%) center/15000%;
        color: $textColor;
    }

    &:active:not(:disabled):not(.disabled) {
        background-color: $activeColor;
        background-size: 100%;
        transition: background 0s;
        color: $textColor;
    }
}

@mixin __button--solid($type,$size) {
    @include __button__base($size);
    @include __button__theme--solid($type);
}

@mixin __button__theme--solid($type) {
    $bg--light: $__text--light;
    $text--light: $__menu--dark;
    $bg--dark: $__text--dark;
    $text--dark: $__menu--light;

    @if $type == primary {
        $bg--light: $__interaction--dark;
        $text--light: $__menu--light;
        $bg--dark: $__interaction--dark;
        $text--dark: $__menu--light;
    } @else if $type == secondary {
        $bg--light: $__text--light;
        $text--light: $__menu--dark;
        $bg--dark: $__text--dark;
        $text--dark: $__menu--light;
    } @else if $type == success {
        $bg--light: $__positive--dark;
        $text--light: $__menu--light;
        $bg--dark: $__positive--dark;
        $text--dark: $__menu--light;
    } @else if $type == alert {
        $bg--light: $__negative--dark;
        $text--light: $__menu--light;
        $bg--dark: $__negative--dark;
        $text--dark: $__menu--light;
    } @else if $type == disable {
        $bg--light: $__bg__gray--1;
        $text--light: $__disabled--light;
        $bg--dark: $__disabled--dark;
        $text--dark: $__text--dark;
    } @else {
        @error "Tipo de botão desconhecido.";
    }

    background-color: $bg--light;
    border: none;
    color: $text--light;

    &:focus {
        background-color: $bg--light;
        color: $text--light;
    }

    @if $type == disable {
        opacity: 1;
    }

    &.active:not(:disabled):not(.disabled) {
        background-color: mix($__black,$bg--light,15%);
        color: $text--light;
    }
    
    @at-root .show > &.dropdown-toggle {
        background-color: mix($__black,$bg--light,15%);
        color: $text--light;
    }

    $hoverBgColor: mix($__white,$bg--light,40%);
    $activeBgColor: mix($__white,$bg--light,90%);

    @include __button__ripple($hoverBgColor, $activeBgColor, $text--light);

    @include __on-theme--dark {
        background-color: $bg--dark;
        border-color: $bg--dark;
        color: $text--dark;

        &:focus {
            background-color: $bg--dark;
            color: $text--dark;
        }

        &.active:not(:disabled):not(.disabled) {
            background-color: mix($__black,$bg--dark,15%);
            color: $text--dark;
        }

        $hoverBgColor: mix($__white,$bg--dark,40%);
        $activeBgColor: mix($__white,$bg--dark,90%);

        @include __button__ripple($hoverBgColor, $activeBgColor, $text--dark);
    }

    @include __on-theme--light {
        background-color: $bg--light;
        border-color: $bg--light;
        color: $text--light;

        &:focus {
            background-color: $bg--light;
            color: $text--light;
        }

        &.active:not(:disabled):not(.disabled) {
            background-color: mix($__white,$bg--light,15%);
            color: $text--light;
        }

        $hoverBgColor: mix($__white,$bg--light,40%);
        $activeBgColor: mix($__white,$bg--light,90%);

        @include __button__ripple($hoverBgColor, $activeBgColor, $text--light);
    }
}

@mixin __button__theme--outline($type) {
    $border--light: $__text--light;
    $text--light: $__text--light;
    $border--dark: $__text--dark;
    $text--dark: $__text--dark;

    @if $type == primary {
        $border--light: $__interaction--light;
        $text--light: $__interaction--light;
        $border--dark: $__interaction--dark;
        $text--dark: $__interaction--dark;
    } @else if $type == secondary {
        $border--light: $__text--light;
        $text--light: $__text--light;
        $border--dark: $__text--dark;
        $text--dark: $__text--dark;
    } @else if $type == success {
        $border--light: $__positive--dark;
        $text--light: $__positive--light;
        $border--dark: $__positive--dark;
        $text--dark: $__positive--dark;
    } @else if $type == alert {
        $border--light: $__negative--dark;
        $text--light: $__negative--light;
        $border--dark: $__negative--dark;
        $text--dark: $__negative--dark;
    } @else if $type == disable {
        $border--light: $__bg__gray--1;
        $text--light: $__disabled--light;
        $border--dark: $__disabled--dark;
        $text--dark: $__text--dark;
    } @else {
        @error "Tipo de botão desconhecido.";
    }

    background-color: transparent;
    border: 1px solid $border--light;
    color: $text--light;

    &:focus {
        background-color: transparent;
        color: $text--light;
    }

    &:focus, &:hover {
        border-color: $border--light;
    }

    @if $type == disable {
        opacity: 1;
    }

    $hoverBgColor: $__hover--light;
    $activeBgColor: mix($__focus,$__hover--light,10%);

    @include __button__ripple($hoverBgColor, $activeBgColor, $text--light);

    @include __on-theme--dark {
        background-color: transparent;
        border-color: $border--dark;
        color: $text--dark;

        &:focus {
            color: $text--dark;
        }

        &:focus, &:hover {
            border-color: $border--dark;
        }

        $hoverBgColor: $__bg__white--2;
        $activeBgColor: $__white;

        @include __button__ripple($hoverBgColor, $activeBgColor, $text--dark);
    }

    @include __on-theme--light {
        background-color: transparent;
        border: 1px solid $border--light;
        color: $text--light;

        &:focus {
            background-color: transparent;
            color: $text--light;
        }

        &:focus, &:hover {
            border-color: $border--light;
        }

        @if $type == disable {
            opacity: 1;
        }

        $hoverBgColor: $__hover--light;
        $activeBgColor: mix($__focus,$__hover--light,10%);

        @include __button__ripple($hoverBgColor, $activeBgColor, $text--light);
    }
}

@mixin __button__theme--clear($type) {
    $text--light: $__text--light;
    $text--dark: $__text--dark;

    @if $type == primary {
        $text--light: $__interaction--light;
        $text--dark: $__interaction--dark;
    } @else if $type == secondary {
        $text--light: $__text--light;
        $text--dark: $__interaction--dark;
    } @else if $type == success {
        $text--light: $__positive--light;
        $text--dark: $__positive--dark;
    } @else if $type == alert {
        $text--light: $__negative--light;
        $text--dark: $__negative--dark;
    } @else if $type == disable {
        $text--light: $__disabled--light;
        $text--dark: $__disabled--dark;
    } @else {
        @error "Tipo de botão desconhecido.";
    }

    background-color: transparent;
    border: none;
    color: $text--light;

    &:focus {
        background-color: transparent;
        color: $text--light;
    }

    @if $type == disable {
        opacity: 1;
    }

    $hoverBgColor: $__hover--light;
    $activeBgColor: mix($__focus,$__hover--light,10%);

    @include __button__ripple($hoverBgColor, $activeBgColor, $text--light);

    @include __on-theme--dark {
        background-color: transparent;
        color: $text--dark;

        &:focus {
            color: $text--dark;
        }

        $hoverBgColor: $__bg__white--2;
        $activeBgColor: $__white;

        @include __button__ripple($hoverBgColor, $activeBgColor, $text--dark);
    }

    @include __on-theme--light {
        background-color: transparent;
        border: none;
        color: $text--light;

        &:focus {
            background-color: transparent;
            color: $text--light;
        }

        @if $type == disable {
            opacity: 1;
        }

        $hoverBgColor: $__hover--light;
        $activeBgColor: mix($__focus,$__hover--light,10%);

        @include __button__ripple($hoverBgColor, $activeBgColor, $text--light);
    }
}

@mixin __button__icon($size: $__button__icon--default) {
    [class^='b3__ico'] {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: __remcalc($size);
        height: __remcalc($size);
        font-size: __remcalc($size);
        line-height: __remcalc($size);

        &:before {
            vertical-align: top;
        }
    }
}

@mixin __button__icon--left($size: $__button__icon-margin--default) {
    [class^='b3__ico'] {
        left: __remcalc($size);
    }
}

@mixin __button__icon--right($size: $__button__icon-margin--default) {
    [class^='b3__ico'] {
        right: __remcalc($size);
    }
}

@mixin __button__icon--only($size: $__button__size--default) {
    padding: 0;
    width: __remcalc($size);
    height: __remcalc($size);

    [class^='b3__ico'] {
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
    }
}

@mixin __button__icon--dropdown($size: $__button__icon--default, $margin: $__button__icon-margin--default, $icon: keyboard_arrow_down) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    content: b3__ico--char($icon);
    font-family: "b3-icons";
    border: 0;
    @include __button__icon--dropdown-update($size, $margin);
}

@mixin __button__icon--dropdown-update($size, $margin) {
    right: __remcalc($margin);
    width: __remcalc($size);
    height: __remcalc($size);
    font-size: __remcalc($size);
    line-height: __remcalc($size);
}

@if $bootstrap {
    .btn {
        @include __button--solid(secondary,$__button__size--default);

        &-primary, &-light {
            @include __button__theme--solid(primary);
        }

        &-info, &-dark {
            @include __button__theme--solid(secondary);
        }

        &-success {
            @include __button__theme--solid(success);
        }

        &-danger, &-warning {
            @include __button__theme--solid(alert);
        }

        &:disabled,
        &.disabled {
            @include __button__theme--solid(disable);
        }

        &-link {
            @include __button__update-corner(48);
            @include __button__theme--clear(primary);

            &.btn-lg {
                @include __button__update-corner(60);
            }

            &.btn-sm {
                @include __button__update-corner(36);
            }

            &:disabled,
            &.disabled {
                @include __button__theme--clear(disable);
            }
        }

        &-outline-primary, &-outline-light {
            @include __button__theme--outline(primary);
        }

        &-outline-secondary, &-outline-dark, &-outline-info {
            @include __button__theme--outline(secondary);
        }

        &-outline-success {
            @include __button__theme--outline(success);
        }

        &-outline-danger, &-outline-warning {
            @include __button__theme--outline(alert);
        }

        &-outline-primary, 
        &-outline-light, 
        &-outline-secondary, 
        &-outline-dark, 
        &-outline-info, 
        &-outline-success,
        &-outline-danger, 
        &-outline-warning {
            &:disabled,
            &.disabled {
                @include __button__theme--outline(disable);
            }
        }

        &[class*='b3__btn-with-icon--left'] {
            @include __button__icon;
            @include __button__icon--left;
        }

        &[class*='b3__btn-with-icon--right'] {
            @include __button__icon;
            @include __button__icon--right;
        }

        &[class*='b3__btn-with-icon--only'] {
            @include __button__icon;
            @include __button__icon--only;
        }

        &.dropdown-toggle {
            &[class*='b3__btn-with-icon--only'] {
                width: __remcalc(48 * 2);
                padding-right: __remcalc(48);
            }

            &[class*='b3__btn-with-icon--left'] {
                &.btn-link {
                    padding-left: __remcalc(42);
                    padding-right: __remcalc(42);
                }
            }

            &:after {
                @include __button__icon--dropdown;
                transition: .3s all ease-out;
            }
        }

        &-lg {
            $buttonSize: $__button__size--large;
            $iconMargin: $__button__icon-margin--large;
            $iconSize: $__button__icon--large;

            @include __button__resize($buttonSize);
            @include __text__button--large;

            &[class*='b3__btn-with-icon--left'] {
                @include __button__icon($iconSize);
                @include __button__icon--left($iconMargin);
            }
    
            &[class*='b3__btn-with-icon--right'] {
                @include __button__icon($iconSize);
                @include __button__icon--right($iconMargin);
            }
    
            &[class*='b3__btn-with-icon--only'] {
                @include __button__icon($iconSize);
                @include __button__icon--only($buttonSize);
            }

            &.dropdown-toggle {
                &[class*='b3__btn-with-icon--only'] {
                    width: __remcalc($buttonSize * 2);
                    padding-right: __remcalc($buttonSize);
                }

                &:after {
                    @include __button__icon--dropdown-update($iconSize, $iconMargin)
                }
            }   
        }

        &-sm {
            $buttonSize: $__button__size--small;
            $iconMargin: $__button__icon-margin--small;
            $iconSize: $__button__icon--small;

            @include __button__resize($buttonSize);

            &[class*='b3__btn-with-icon--left'] {
                @include __button__icon--left($iconMargin);
            }
    
            &[class*='b3__btn-with-icon--right'] {
                @include __button__icon--right($iconMargin);
            }
    
            &[class*='b3__btn-with-icon--only'] {
                @include __button__icon--only($buttonSize);
            }

            &.dropdown-toggle {
                &[class*='b3__btn-with-icon--only'] {
                    width: __remcalc($buttonSize * 2);
                    padding-right: __remcalc($buttonSize);
                }  
                &:after {
                    @include __button__icon--dropdown-update($iconSize, $iconMargin)
                }
            }
        }
    }

    .dropdown {
        & > .btn {
            
        }
        
        &.show .btn {
            border-top-left-radius: __remcalc(24);
            border-top-right-radius:  __remcalc(24);
            border-bottom-left-radius: .25rem;
            border-bottom-right-radius: .25rem;

            &:after {
                transform: rotate(180deg) translateY(50%);
            }
        }

        .dropdown-menu {
            width: 100%;
        }
    }

    
}

@if $foundation {
    .button {
        @include __button--solid(secondary,$__button__size--default);

        &.primary {
            @include __button__theme--solid(primary);
        }

        &.success {
            @include __button__theme--solid(success);
        }

        &.alert, &.warning {
            @include __button__theme--solid(alert);
        }

        &:disabled,
        &.disabled,
        &[disabled] {
            @include __button__theme--solid(disable);

            &.primary, &.secondary, &.success, &.alert, &.warning {
                @include __button__theme--solid(disable);
            }
        }

        &.hollow {
            @include __button__theme--outline(primary);

            &.secondary {
                @include __button__theme--outline(secondary);
            }
    
            &.success {
                @include __button__theme--outline(success);
            }
    
            &.alert, &.warning {
                @include __button__theme--outline(alert);
            }

            &:disabled,
            &.disabled,
            &[disabled] {
                @include __button__theme--outline(disable);

                &.primary, &.secondary, &.success, &.alert, &.warning {
                    @include __button__theme--outline(disable);
                }
            }
        }

        &.clear {
            @include __button__theme--clear(primary);

            &.secondary {
                @include __button__theme--clear(secondary);
            }
    
            &.success {
                @include __button__theme--clear(success);
            }
    
            &.alert, &.warning {
                @include __button__theme--clear(alert);
            }

            &.tiny {
                @include __button__update-corner(24);
            }
    
            &.small {
                @include __button__update-corner(36);
            }
    
            &.large {
                @include __button__update-corner(60);
            }

            &:disabled,
            &.disabled,
            &[disabled] {
                @include __button__theme--clear(disable);

                &.primary, &.secondary, &.success, &.alert, &.warning {
                    @include __button__theme--clear(disable);
                }
            }
        }

        &[class*='b3__btn-with-icon--left'] {
            @include __button__icon;
            @include __button__icon--left;
        }

        &[class*='b3__btn-with-icon--right'] {
            @include __button__icon;
            @include __button__icon--right;
        }

        &[class*='b3__btn-with-icon--only'] {
            @include __button__icon;
            @include __button__icon--only;
        }

        &.arrow-only {
            padding-left: __remcalc(3); 
        }

        &.dropdown {
            &[class*='b3__btn-with-icon--only'] {
                width: __remcalc(48 * 2);
                padding-right: __remcalc(48);
            }

            &[class*='b3__btn-with-icon--left'] {
                &.btn-link {
                    padding-left: __remcalc(48 / 2);
                    padding-right: __remcalc(48 / 2);
                }
            }

            &:after {
                @include __button__icon--dropdown;
            }
        }

        &.tiny {
            $buttonSize: $__button__size--tiny;
            $iconMargin: $__button__icon-margin--tiny;
            $iconSize: $__button__icon--tiny;

            @include __button__resize($buttonSize);
            @include __button__update-corner($buttonSize);
            font-size: .8rem;

            &[class*='b3__btn-with-icon--left'] {
                padding-left: __remcalc(26);
                @include __button__icon($iconSize);
                @include __button__icon--left($iconMargin);
            }
    
            &[class*='b3__btn-with-icon--right'],
            &.dropdown {
                padding-right: __remcalc(26);
            }

            &[class*='b3__btn-with-icon--right'] {
                @include __button__icon($iconSize);
                @include __button__icon--right($iconMargin);
            }
    
            &[class*='b3__btn-with-icon--only'] {
                @include __button__icon($iconSize);
                @include __button__icon--only($buttonSize);
            }

            &.dropdown {
                &[class*='b3__btn-with-icon--only'] {
                    width: __remcalc($buttonSize * 2);
                    padding-right: __remcalc($buttonSize);
                }

                &:after {
                    @include __button__icon--dropdown-update($iconSize, $iconMargin)
                }
            }
        }

        &.small {
            $buttonSize: $__button__size--small;
            $iconMargin: $__button__icon-margin--small;
            $iconSize: $__button__icon--small;

            @include __button__resize($buttonSize);
            @include __text__button;

            &[class*='b3__btn-with-icon--left'] {
                @include __button__icon--left($iconMargin);
            }
    
            &[class*='b3__btn-with-icon--right'] {
                @include __button__icon--right($iconMargin);
            }
    
            &[class*='b3__btn-with-icon--only'] {
                @include __button__icon--only($buttonSize);
            }

            &.dropdown {
                &[class*='b3__btn-with-icon--only'] {
                    width: __remcalc($buttonSize * 2);
                    padding-right: __remcalc($buttonSize);
                }
                &:after {
                    @include __button__icon--dropdown-update($iconSize, $iconMargin)
                }
            }
        }

        &.large {
            $buttonSize: $__button__size--large;
            $iconMargin: $__button__icon-margin--large;
            $iconSize: $__button__icon--large;

            @include __button__resize($buttonSize);
            @include __text__button--large;

            &[class*='b3__btn-with-icon--left'] {
                @include __button__icon($iconSize);
                @include __button__icon--left($iconMargin);
            }
    
            &[class*='b3__btn-with-icon--right'] {
                @include __button__icon($iconSize);
                @include __button__icon--right($iconMargin);
            }
    
            &[class*='b3__btn-with-icon--only'] {
                @include __button__icon($iconSize);
                @include __button__icon--only($buttonSize);
            }

            &.dropdown {
                &[class*='b3__btn-with-icon--only'] {
                    width: __remcalc($buttonSize * 2);
                    padding-right: __remcalc($buttonSize);
                }
                &:after {
                    @include __button__icon--dropdown-update($iconSize, $iconMargin)
                }
            }
        }
    }
}

